import { hyphenateSync } from 'hyphen/no';
import { useContext } from 'react';
import { LayoutSettingsContext } from '../Layout/Hooks/useLayoutSettings';

type HyphenateTitleElementProps = {
    title: string;
    minWordLength?: number;
};

export const HyphenateTitleElement = ({ title, minWordLength }: HyphenateTitleElementProps) => {
    const layoutContext = useContext(LayoutSettingsContext);

    if (!title || title.length < 1) {
        return undefined;
    }
    if (title.length == 1) {
        return <>{title}</>;
    }

    const effectiveMinWordLength = minWordLength ?? layoutContext?.hypphenMinWordLenght?.value ?? 12;

    return <>{hyphenateSync(title, { minWordLength: effectiveMinWordLength })}</>;
};

export default HyphenateTitleElement;
